import React from "react";
import "./BackgroundVideo.css"; // Import CSS untuk styling
import videoSource from "../assets/zero.mp4"; // Import file video

const BackgroundVideo = () => {
  return (
    <div className="video-container">
      {/* Video Background */}
      <video autoPlay loop muted className="background-video">
        <source src={videoSource} type="video/mp4" />
        Your browser does not support the video tag.
      </video>

      {/* Overlay Gelap */}
      <div className="overlay"></div>

      {/* Teks Placeholder */}
      <div className="centered-text">
      Is That Cruel?
      </div>
    </div>
  );
};

export default BackgroundVideo;
