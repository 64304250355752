import React from "react";
import BackgroundVideo from "./components/BackgroundVideo"; // Import komponen background video

function App() {
  return (
    <div className="App">
      <BackgroundVideo />
    </div>
  );
}

export default App;
